import { model } from '@telekomconsalting/dex-guru-model'

import { FullSidebarsModifierValues, TransactionTypes } from '../../model'
import { getTokenAddressFromId } from '../../utils'

export const getDeltaClassName = (
  transactionType: string,
  currentTokenId: string,
  fromAddress: string | null,
  modifier: string,
  traderProfileToken?: string | null
): string => {
  const currentTokenAddress = getTokenAddressFromId(currentTokenId).toLowerCase()
  if (
    transactionType === TransactionTypes.swap &&
    modifier === FullSidebarsModifierValues.profileTradingHistory
  ) {
    if (!traderProfileToken) {
      return 'transparent'
    }
    const traderProfileTokenAddress = getTokenAddressFromId(traderProfileToken).toLowerCase()
    return fromAddress === traderProfileTokenAddress ? 'down' : 'up'
  }
  if (transactionType === TransactionTypes.mint) {
    return 'plus'
  }
  if (transactionType === TransactionTypes.burn) {
    return 'minus'
  }
  if (transactionType === TransactionTypes.swap) {
    return fromAddress === currentTokenAddress ? 'down' : 'up'
  }

  return 'transparent'
}

export const getWalletCategory = (walletsCategories?: model.Category[] | null): model.Category => {
  if (!Array.isArray(walletsCategories)) {
    return '' as model.Category
  }

  return walletsCategories[0]?.toLocaleLowerCase() as model.Category
}
